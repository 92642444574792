import React from "react";
import Sidebar from "../SidebarUsuarios";

export default function BlogCreadoresSerCreador() {
  return (
    <>
      <div className="module ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-8">
              <article className="post">
                <div className="post-preview-phone">
                  {/* <img
                    src="assets/img/blog/blog1RegistrarsePortada.jpg"
                    alt="article"
                    className="img-fluid"
                  /> */}
                </div>
                <div className="post-wrapper">
                  <div className="post-header">
                    <h1 className="post-title">Quiero ser Creador de Contenido!</h1>
                  </div>
                  <div className="post-content">
                    <p>
                      Una vez registrado, debes presionar el botón de Crear <span></span> 
                      <img src="assets/img/blog/iconoSubirContenido.jpg" alt="Subir Contenido" width={35} height={25}/>  en su menu inferior.
                      <span></span> 
                    </p>
                    <p>
                      Completa los datos y carga los archivos solicitados de tu documento para validar tu identidad. Recuerda que debes ser mayor de edad en tu país de residencia.
                    </p>
                    <p>
                      Después de enviar la solicitud, en las próximas horas, te enviaremos los resultados a tu correo electrónico. Si no encuentras la respuesta en tu bandeja de entrada, asegúrate de revisar tu carpeta de spam o correo no deseado.
                    </p>
                    <p>
                      Si tu solicitud es aprobada, debes cerrar sesión y volver a iniciar sesión para activar tu cuenta como creador de contenido. Si tu solicitud es rechazada, te brindaremos algunas razones por las cuales no fue aprobada en el mismo correo electrónico.
                    </p>
                    <div className="post-header">
                      <h1 className="post-title">Pasos que debes seguir:</h1>
                    </div>
                    <ol>
                      <li><b><strong> Presiona el botón de Crear .  <span></span>
                         <img src="assets/img/blog/iconoSubirContenido.jpg" alt="Subir Contenido" width={35} height={25}/> 
                         </strong></b></li>
                      <li><b><strong> Foto frontal del documento de identidad. </strong></b></li>
                      <li><b><strong> Foto posterior del documento de identidad. </strong></b></li>
                      <li><b><strong> Selfie sosteniendo el documento de identidad al lado de tu rostro. </strong></b></li>
                      <li><b><strong> Completa los datos. </strong></b></li>
                      <li><b><strong> Confirma y envía la solicitud. </strong></b></li>
                    </ol>
                    <p>
                      Ten en cuenta que todos los datos son confidenciales y los usuarios no tienen acceso a ellos. El único dato visible será tu nombre de usuario.
                    </p>
                    <div className="post-header">
                      <h1 className="post-title">Recomendaciones para enviar la solicitud correctamente:</h1>
                    </div>
                    <ol>
                      <li><b><strong> No uses el flash y realiza las fotos en un lugar bien iluminado. </strong></b></li>
                      <li><b><strong> Revisa que se pueda ver claramente toda la información y que no haya nada tapado con tus dedos. </strong></b></li>
                      <li><b><strong> Foto posterior del documento de identidad. </strong></b></li>
                      <li><b><strong> El documento de identidad debe pertenecer a la persona titular. </strong></b></li>
                      <li><b><strong> Los archivos deben ser fotos (no videos). </strong></b></li>
                    </ol>
                    <p>
                      La verificación de identidad es importante para proteger la propiedad intelectual, cumplir con las regulaciones legales y evitar perfiles falsos.
                    </p>
                    <p>
                      La aceptacion del perfil puede tardar hasta 24 hs.
                    </p>
                  </div>
                  <div className="post-footer">
                    <div className="post-tags">
                      <a href="/usuarios">Usuarios</a>
                      <a href="/usuarios">Login</a>
                    </div>
                  </div>
                </div>
              </article>
              {/* <Comments /> */}
            </div>
            <Sidebar />
          </div>
        </div>
      </div>
    </>
  );
}
