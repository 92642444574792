import React from "react";

export default function DownloadCta() {
  return (
    <>
      <section id="download" className="video-promo ptb-100">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-7">
              <div className="download-content">
                <h2>Descarga la aplicación de nuestra Web. Es instalable en cualquier dispositivo</h2>
                <p>
                  Podes descargar hotmy desde cualquiera de los medios disponibles.
                  En hotmy vas a poder monetizar todo tu contenido de una manera unica.
                </p>
                <div className="download-btn mt-5 button-center-mobile">
                  <a href="https://hotmy.app/" className="btn solid-btn m-3">
                    <span className="ti-android"></span> Google Play
                  </a>
                  <a href="https://hotmy.app/" className="btn outline-btn m-3">
                    <span className="ti-apple"></span> App Store
                  </a>
                  <a href="https://hotmy.app/" className="btn outline-btn m-3">
                  <span className="ti-html5"></span> Ir a la App
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="download-img mt-lg-5 mt-md-5 mt-sm-0">
                <img
                  src="assets/img/Descargas-4.png"
                  alt="download"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
