import React from "react";
import { CountUp } from 'use-count-up'


export default function Counter({ className }) {
  return (
    <>
      <div className={`counter ${className}`}>
        <div className="container">
          <div className="row">
            <ul className="list-inline counter-wrap">
              <li className="list-inline-item">
                <div className="single-counter text-center">
                  <span> 
                    <CountUp
                     isCounting 
                     end={129030} 
                     duration={3.9} />
                    </span>
                  <h6>Usuarios Registrados</h6>
                </div>
              </li>
              <li className="list-inline-item">
                <div className="single-counter text-center">
                  <span>
                  <CountUp
                     isCounting 
                     end={21992} 
                     duration={3.0} />
                    </span>
                  <h6>Creadores de Contenido</h6>
                </div>
              </li>
              <li className="list-inline-item">
                <div className="single-counter text-center">
                  <span>
                  <CountUp
                     isCounting 
                     end={12023} 
                     duration={2.0} />
                    </span>
                  <h6>Descargas de la App</h6>
                </div>
              </li>
              <li className="list-inline-item">
                <div className="single-counter text-center">
                  <span>+ 3Mm</span>
                  <h6>Ganancias Repartidas</h6>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
