
import React from "react";
import Sidebar from "../SidebarUsuarios";

export default function BlogComoCompartirPerfil() {
    return (
        <>
            <div className="module ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-8">
                            <article className="post">
                                <div className="post-preview-phone">
                                </div>
                                <div className="post-wrapper">
                                    <div className="post-header">
                                        <h1 className="post-title">Comparti tu perfil</h1>
                                    </div>
                                    <div className="post-content">
                                        <p>
                                            Comparti el perfil en tus redes sociales, es la manera mas facil de que te conozcan.
                                        </p>
                                        <p>
                                            Cuantas mas veces compartas tu enlaces mas posibilidad tendras de vender con gente de tu propia red. 
                                        </p>
                                        <p>
                                            Esta accion aumenta la venta promedio en un 40%. 
                                        </p>

                                        <div className="post-header">
                                            <h1 className="post-title">Pasos para compartir tu perfil</h1>
                                        </div>
                                        <ol>
                                            <li><b>Ingresa a tu cuenta con un perfil de creador aceptado</b></li>
                                            <li><b><strong> Presiona del menu inferior el ultimo icono "Mi Perfil" </strong></b></li>
                                            <li><b><strong> En el menu lateral presiona la seleccion de "Mi Perfil" debajo de tu foto de perfil</strong></b></li>
                                            <li><b><strong> En la pantalla de mi perfil, sobre el borde derecho, esta la opcio de Compartir Perfil. </strong></b></li>
                                        </ol>

                                        {/* <img
                                            src="assets/img/blog/blog1RegistrarsePortada.jpg"
                                            alt="article"
                                            className="img-fluid"
                                        /> */}
                                        
                                        <p>
                                            Si no encontras como llegar a tu perfil podes presionar el siguiente enlace, en caso de ser creador te llevara directamente a tu perfil.
                                        </p>
                                        <p>
                                            <a href="https://hotmy.app/myprofile">
                                            Ir a Mi Perfil de Creador para compartir mis enlaces.
                                            </a>
                                        </p>
                                    </div>
                                    <div className="post-footer">
                                        <div className="post-tags">
                                            <a href="/creadores">Creadores</a>
                                            <a href="/usuarios">Usuarios</a>
                                        </div>
                                    </div>
                                </div>
                            </article>
                            {/* <Comments /> */}
                        </div>
                        <Sidebar />
                    </div>
                </div>
            </div>
        </>
    );
}
