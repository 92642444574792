import React from "react";
import { Routes, Route } from "react-router-dom";
import ChangePassword from "../components/others/ChangePassword";
import ComingSoon from "../components/others/ComingSoon";
import LoginTwo from "../components/others/LoginTwo";
import NotFound from "../components/others/NotFound";
import PasswordReset from "../components/others/PasswordReset";
import ThankYou from "../components/others/ThankYou";
import Login from "../pages/Login";
import HomeCero from "../themes/index-0/HomeCero";
import HomeOne from "../themes/index-1/HomeOne";
import HomeTwo from "../themes/index-2/HomeTwo";
import HomeThree from "../themes/index-3/HomeThree";
import HomeFour from "../themes/index-4/HomeFour";
import HomeFive from "../themes/index-5/HomeFive";
import HomeSix from "../themes/index-6/HomeSix";
import HomeSeven from "../themes/index-7/HomeSeven";
import HomeEight from "../themes/index-8/HomeEight";
import OurTeam from "../pages/OurTeam";
import TeamDetails from "../pages/TeamDetails";
import Download from "../pages/Download";
import Review from "../pages/Review";
import Faq from "../pages/Faq";
import SignupTwo from "../components/others/SignupTwo";
import SignupPage from "../pages/SignupPage";
import BlogAgencias from "../pages/BlogAgencias";
import BlogWithSidebar from "../pages/BlogWithSidebar";
import BlogDetails from "../pages/BlogDetails";
import ScrollToTop from "./ScrollToTop";
import ContactUs from "../pages/Contact";
import Branding from "../pages/Branding";
import Registrarse from "../pages/help/HelpUserComoRegistrarse"
import BlogCreadores from "../pages/BlogCreadores";
import BlogUsuarios from "../pages/BlogUsuarios";
import TerminosCondiciones from "../pages/TerminosCondiciones";
import HelpUserComoComprarUnPack from "../pages/help/HelpUserComoComprarUnPack";
import HelpUserComoRegistrarse from "../pages/help/HelpUserComoRegistrarse";
import HelpCreatorComoSerCreadorDeContenido from "../pages/help/HelpCreatorComoSerCreadorDeContenido";
import HelpCreatorComoCompartirMiPerfil from "../pages/help/HelpCreatorComoCompartirMiPerfil";
import HelpCreatorComoCrearUnPack from "../pages/help/HelpCreatorComoCrearUnPack";
import HelpCreatorComoSuboFotosVideosFeed from "../pages/help/HelpCreatorComoSuboFotosVideosFeed"
import HelpCreatorExtraerDinero from "../pages/help/HelpCreatorExtraerDinero";
import HelpCreatorComoEditarMiPefil from "../pages/help/HelpCreatorComoEditarMiPefil";
import ExclusiveAgreementPage from "../pages/ExclusiveAgreementPage";

const AppRoutes = () => {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<HomeCero />} />
        <Route path="/home-one" element={<HomeOne />} />
        <Route path="/home-two" element={<HomeTwo />} />
        <Route path="/home-three" element={<HomeThree />} />
        <Route path="/home-four" element={<HomeFour />} />
        <Route path="/home-five" element={<HomeFive />} />
        <Route path="/home-six" element={<HomeSix />} />
        <Route path="/home-seven" element={<HomeSeven />} />
        <Route path="/home-eight" element={<HomeEight />} />
        <Route path="/our-team" element={<OurTeam />} />
        <Route path="/team-details" element={<TeamDetails />} />
        <Route path="/thank-you" element={<ThankYou />} />
        <Route path="/coming-soon" element={<ComingSoon />} />
        <Route path="/login" element={<Login />} />
        <Route path="/login-two" element={<LoginTwo />} />
        <Route path="/signup-two" element={<SignupTwo />} />
        <Route path="/basic-signup" element={<SignupPage />} />
        <Route path="/reset-password" element={<PasswordReset />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/download" element={<Download />} />
        <Route path="/review" element={<Review />} />
        <Route path="/exclusive-agreement" element={<ExclusiveAgreementPage />} />
        <Route path="/faq" element={<Faq />} />
          <Route path="/usuarios" element={<BlogUsuarios />} />
          <Route path="/creadores" element={<BlogCreadores />} />
          <Route path="/agencias" element={<BlogAgencias />} />
            {/* INICIO PREGUNTAS */}
            <Route path="/registrarse" element={<HelpUserComoRegistrarse />} />
            <Route path="/comprar-un-pack" element={<HelpUserComoComprarUnPack />} />
            <Route path="/como-ser-creador-de-contenido" element={<HelpCreatorComoSerCreadorDeContenido />} />
            <Route path="/como-compartir-mi-perfil" element={<HelpCreatorComoCompartirMiPerfil/>} />
            <Route path="/como-crear-un-pack" element={<HelpCreatorComoCrearUnPack/>} />
            <Route path="/como-subir-fotos-videos" element={<HelpCreatorComoSuboFotosVideosFeed/>} />
            <Route path="/como-extraer-dinero" element={<HelpCreatorExtraerDinero/>} />
            <Route path="/como-editar-mi-perfil" element={<HelpCreatorComoEditarMiPefil/>} />
            <Route path="/compartir-enlace-referido" element={<Registrarse />} />
            {/* FIN PREGUNTAS */}
        <Route path="/blog-sidebar" element={<BlogWithSidebar />} />
        <Route path="/blog-details" element={<BlogDetails />} />
        <Route path="/contactUs" element={<ContactUs/>}/>
        <Route path="/branding" element={<Branding/>}/>
        <Route path="/terminos-condiciones" element={<TerminosCondiciones/>}/>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default AppRoutes;
