import React from "react";
import Counter from "../../components/counter/Counter";
import SwipperGallery from "../../components/swipper/SwipperGallery";

export default function HeroTwo() {
  return (
    <>
      <section
        className="hero-section pt-100"
        style={{
          background:
            "url('assets/img/00-Fondo3b.jpg')no-repeat center center / cover",
        }}
      >
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-6 col-lg-6">
              <div className="hero-content-left text-white mt-5">
                {/* <span className="text-uppercase h6">
                  Customer First Priority
                </span> */}
                <h1 className="text-white">
                  Regístrate gratis y descubre lo fácil que es ganar dinero con tus fotos y videos.
                </h1>
                <p className="lead">
                  Subi contenido, crea packs, genera acuerdos comerciales tenemos muchas herramientas para vos. 
                </p>
                <a href="https://hotmy.app" className="btn hotmy-store-btn">
                  Ir a la App
                </a>
              </div>
            </div>
            <div className="col-md-6 col-lg-5">
              <div className="hero-animation-img">
              <SwipperGallery/>
                {/* <img
                  src="assets/img/Smartphone-2.png"
                  alt="img"
                  className="img-fluid"
                /> */}
              </div>
            </div>
          </div>
          {/* Contador de clientes */}
          <Counter />
        </div>
      </section>
    </>
  );
}
