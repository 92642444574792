import React from "react";
import Accordion from "../components/Faqs/Accordion";
import Footer from "../components/layout/footer/Footer";
import Navbar from "../components/layout/header/Navbar";
import Layout from "../components/layout/Layout";
import PageHeader from "../components/team/PageHeader";
import PromoTwo from "../components/promo/UsersFrecuentyAsks" 

export default function Faq() {
  return (
    <Layout>
      <Navbar darkBg />
      <PageHeader HeaderTitle="Preguntas Frecuentes" Parent="Info" PageTitle="Faq" />
      <Accordion Title />
      <PromoTwo/>
      <Footer />
    </Layout>
  );
}
