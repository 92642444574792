import React from "react";
// import { Link } from "react-router-dom";

const Cta = () => {
  return (
    <>
      <section
        className="download-section pt-100 background-img"
        style={{
          background:
            "url('assets/img/Descargas-4.png') no-repeat center center / cover",
        }}
      >
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-7">
              <div className="download-content text-white pb-100">
                <h2 className="text-white">
                  Acuerdos Comerciales, Conoce esta funcionalidad unica que tenemos para vos.
                </h2>
                <p className="lead">
                Esta funcionalidad permite normalizar todos los acuerdos que se realizan con los artistas, influencers en un marco seguro y transparente de trabajo.
                Los artistas van a poder pre-acordar con el fotógrafo/manager, una comisión que va a corresponder al servicio de multimedia aplicado en los Packs. 
                Cuando se genere una venta, el dinero se dividirá automáticamente según lo acordado entre las partes.
                </p>

                {/* <div className="download-btn">
                  <Link to="/Agreement" className="btn google-play-btn m3">
                    ir a Acuerdos
                  </Link>
                </div> */}
              </div>
            </div>
            <div className="col-md-4">
              <div className="download-img d-flex align-items-end">
                <img
                  src="assets/img/Smartphone-3d.png"
                  alt="download"
                  className="img-fluid-3d"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Cta;
