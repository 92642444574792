import React from "react";
import SidebarCreators from "../SidebarCreators";

export default function BlogEditarMiPerfil() {
    return (
        <>
            <div className="module ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-8">
                            <article className="post">
                                <div className="post-preview-phone">
                                </div>
                                <div className="post-wrapper">
                                    <div className="post-header">
                                        <h1 className="post-title">Editar mi Perfil</h1>
                                    </div>
                                    <div className="post-content">
                                        <p>
                                            Edita tu perfil, cambia foto de perfil y de portada, modifica los valores de tus subscripciones, actualiza tus datos personales.
                                        </p>
                                        <p>
                                            Modificar tus portadas frecuentemente, ayudara a aumentar tus ventas. 
                                        </p>

                                        <div className="post-header">
                                            <h1 className="post-title">Pasos para Editar tu Perfil</h1>
                                        </div>
                                        <ol>
                                            <li><b><strong> Presiona el boton de tu perfil , abajo a la derecha </strong></b></li>
                                            <li><b><strong> En el menu lateral presiona el Editar Perfil </strong></b></li>
                                        </ol>
                                        <div className="post-header">
                                            <h1 className="post-title">Opciones Disponibles en Editar Perfil</h1>
                                        </div>
                                        <ol>
                                            <li><b><strong> Cambiar Foto de Perfil. </strong></b></li>
                                            <li><b><strong> Cambiar Foto de Portada. </strong></b></li>
                                            <li><b><strong> Modificar texto Acerca de mi, este texto se visualizara para que los usuarios te conozcan. </strong></b></li>
                                            <li><b><strong> Preferencias. </strong></b></li>
                                            <li><b><strong> Subscripciones, podras editar los precios de tus subscripciones </strong></b></li>
                                            <li><b><strong> Mis datos, datos personales para contacto, son de uso exclusivo de la app y no estan disponibles al publico. </strong></b></li>
                                            <li><b><strong> Links de Referidos, es un enlace para aumentar tus ventan ingresando nuevos creadores. </strong></b></li>
                                            <li><b><strong> Caracteristicas, son datos acerca de vos y tu caracteristicas, sirven para que los usuarios filtren mejor por sus gustos. </strong></b></li>
                                        </ol>
                                        {/* <img
                                            src="assets/img/blog/blog1RegistrarsePortada.jpg"
                                            alt="article"
                                            className="img-fluid"
                                        /> */}
                                    </div>
                                    <div className="post-footer">
                                        <div className="post-tags">
                                            <a href="/creadores">Creadores</a>
                                            <a href="/usuarios">Usuarios</a>
                                        </div>
                                    </div>
                                </div>
                            </article>
                            {/* <Comments /> */}
                        </div>
                        <SidebarCreators />
                    </div>
                </div>
            </div>
        </>
    );
}
