import React from "react";
import Sidebar from "../SidebarUsuarios";

export default function BlogUsuariosRegistrarse() {
  return (
    <>
      <div className="module ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-8">
              <article className="post">
                <div className="post-preview-phone">
                  <img
                    src="assets/img/blog/ComoRegistrarme.jpg"
                    alt="article"
                    className="img-fluid"
                  />
                </div>
                <div className="post-wrapper">
                  <div className="post-header">
                    <h1 className="post-title">Como Registrarse?</h1>
                  </div>
                  <div className="post-content">
                    <p>
                      Es muy facil registrarse a hotmy tenes 2 opciones.
                    </p>
                    <ol>
                      <li><b>Sincronizacion por Google</b></li>
                      <li><b><strong>Registrate Gratis</strong></b></li>
                    </ol>
                    <p>
                      Todos nuestros metodos de accesos estan segurizados, mantenemos la integridad y discresion de cada usuario. Nunca te pediremos datos personales.
                    </p>
                    <div className="post-header">
                      <h1 className="post-title">Sincronizacion con Google</h1>
                    </div>
                    <ol>
                      <li><b>Click en el Boton iniciar Sesion con Google</b></li>
                      <li><b><strong> Selecciona la cuenta de Google que desees sincronizar. </strong></b></li>
                      <li><b><strong> Completa los datos de Pais y codigo de referido (opcional). </strong></b></li>
                    </ol>
                    <p>
                      2** - Si no estas logueado, ingresa tu usuario y clave de mail para acceder a tu cuenta de gmail.
                      <p></p>
                      3** - El codigo de Referido es opcional y solo se aplica cuando ingresas desde un enlace de referidos.
                    </p>
                    <div className="post-header">
                      <h1 className="post-title">Crea una cuenta nueva Gratis!</h1>
                    </div>

                    <img src="assets/img/blog/1registrarse.jpg" alt="como registrarse en hotmy" width='250px'></img>
                    <p>
                      En el login de la app en <a href="https://hotmy.app">https://hotmy.app</a> en el ultimo espacio de la pagina presiona el boton <strong>Registrarse Gratis</strong>.
                    </p>
                    <p>
                      Al presionar ese boton se visualizara la pantalla que vemos a continuacion.
                    </p>
                    <img src="assets/img/blog/2registrarse.jpg" alt="como registrarse en hotmy" width='250px'></img>
                    <p>
                      Debemos completar todos los datos para crear una cuenta.
                    </p>

                    <ol>
                      <li><b><strong> Email : Ingresar el mail que queres usar en la aplicacion. </strong></b></li>
                      <li><b><strong> Constrasena : Ingresar la clave que queres usar en la aplicacion. </strong></b></li>
                      <li><b><strong> Repetir Constrasena: Ingresa nuevamente la contrasena del paso anterior. </strong></b></li>
                      <li><b><strong> Pais de Residencia: Selecciona tu pais de residencia y donde tienes tus tarjetas o cuentas de pago. </strong></b></li>
                      <li><b><strong> Codigo de Referido: Es opcional, no es necesario su ingreso. Si venis de un link de referido se completa automaticamente. </strong></b></li>
                    </ol>
                    <p>
                    <strong> Importante: </strong> Luego de crear la cuenta, te llegara un mail para validar tu registro, sin validar sesion no podras usar tu aplicativo. 
                    </p>
                  </div>
                  <div className="post-footer">
                    <div className="post-tags">
                      <a href="/usuarios">Usuarios</a>
                      <a href="/usuarios">Login</a>
                    </div>
                  </div>
                </div>
              </article>

              {/* <Comments /> */}
            </div>
            <Sidebar />
          </div>
        </div>
      </div>
    </>
  );
}
