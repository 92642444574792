import React from "react";
import ExpandexInfo from "./ExpandexInfo"

const ExclusiveAgreementAsked = () => {
  return (
    <>
      <div className="overflow-hidden">
        <section id="about" className="about-us ptb-100 background-shape-img">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-7">
                <div className="about-content-left section-heading">
                  {/* <h2>
                    Acuerdos Exclusivos Hotmy
                  </h2> */}
                  <h2>
                    Prestaciones del Creador:
                  </h2>
                  <h5>
                    Son las actividades que el usuario debe cumplir obligatoriamente para respetar el acuerdo exclusivo.
                  </h5>
                  <ExpandexInfo
                    Title={"Perfil con venta de contenido sin restricciones geográficas."}
                    expandDetails={"El creador no podrá tener ninguna limitación de venta geográfica en ningún país."}
                    idExpandex={10}
                  />
                  {/* <ExpandexInfo
                    Title={"Exclusividad de Plataforma."}
                    expandDetails={"La unica plataforma que podra usar el usuario sera Hotmy, no podra utilizar otras plataformas como Of, cafecito, matecito, etc."}
                    idExpandex={11}
                  /> */}
                  <ExpandexInfo
                    Title={"Link de su perfil Hotmy en la biografía de sus Redes Sociales"}
                    expandDetails={"Es requerido que el link este en tu biografía de instagram."}
                    idExpandex={15}
                  />
                  <ExpandexInfo
                    Title={"Posteos en todas sus Redes Sociales."}
                    expandDetails={"tu red es tu vidriera, todo el material que te enviemos debe ser publicado en tus redes sociales para aumentar y fidelizar clientes"}
                    idExpandex={12}
                  />
                  <ExpandexInfo
                    Title={"Compartí el link de referidos y gana hasta 10% mas"}
                    expandDetails={"Tu link de referidos es una herramienta de generar dinero constante, si una creadora entra con tu link de referido vas a obtener hasta el 10% de cada una de sus ventas sin afectar su ganancia."}
                    idExpandex={13}
                  />
                  <ExpandexInfo
                    Title={"Asistencia a Producciones Oficiales"}
                    expandDetails={"Hmy organizará producciones oficales de asistencia obligatoria para crear contenido exclusivo para la plataforma."}
                    idExpandex={14}
                  />
                  <ExpandexInfo
                    Title={"Reposteos relacionados obligatorios sobre el contenido de hotmy"}
                    expandDetails={"Reposteos de todas las publicaciones en redes sociales que seas etiquetada."}
                    idExpandex={16}
                  />
                  <ExpandexInfo
                    Title={"Participación obligatoria en producciones oficiales."}
                    expandDetails={"Participación obligatoria en producciones oficiales para generar contenido exclusivo para su perfil y la plataforma."}
                    idExpandex={17}
                  />
                </div>
              </div>
              <div className="col-md-5">
                <div className="about-content-right">
                  <img
                    // src="assets/img/image-11.png"
                    src="assets/img/Smartphone-3d.png"
                    alt="about us"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ExclusiveAgreementAsked;
