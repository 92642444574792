import Footer from "../../components/layout/footer/Footer";
import FeatureImg from "../../components/features/FeatureImg";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import VideoPromoTwo from "../../components/promo/VideoPromoTwo";
import HeroTwo from "./HeroCero";
import BrandCarousel from "../../components/testimonial/BrandCarousel";
import Screenshot from "../../components/screenshot/Screenshot";
import Cta from "../../components/cta/Cta";

export default function HomeCero() {
  return (
    <Layout>
      <Navbar darkBg />
      <HeroTwo />
      <FeatureImg ImgSource="assets/img/img-14-header.webp" />
      <VideoPromoTwo />
      <Screenshot hasBg />
      <Cta />
      <BrandCarousel />
      <Footer space />
    </Layout>
  );
}
