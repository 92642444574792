import React from "react";
import BlogCrearUnPack from "../../components/blogs/BlogDetails/BlogCrearUnPack";
import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/team/PageHeader";

export default function HelpCreatorComoCrearUnPack() {
  return (
    <Layout>
      <Navbar darkBg />
      <PageHeader
        HeaderTitle="Como Crear un Pack"
        PageTitle="Como crear tu Pack"
        Parent="Ayuda"
      />
      <BlogCrearUnPack />
      <Footer />
    </Layout>
  );
}