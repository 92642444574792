import React from "react";
import BlogExtraerDinero from "../../components/blogs/BlogDetails/BlogExtraerDinero";
import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/team/PageHeader";

export default function HelpCreatorExtraerDinero() {
  return (
    <Layout>
      <Navbar darkBg />
      <PageHeader
        HeaderTitle="Como extraigo Dinero"
        PageTitle="Como extraer Dinero"
        Parent="Ayuda"
      />
      <BlogExtraerDinero />
      <Footer />
    </Layout>
  );
}