import React from "react";
import BlogUsuariosRegistrarse from "../../components/blogs/BlogDetails/BlogUsuariosRegistrarse";
import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/team/PageHeader";

export default function HelpUserComoRegistrarse() {
  return (
    <Layout>
      <Navbar darkBg />
      <PageHeader
        HeaderTitle="Registrarse"
        PageTitle="Como Registrarse"
        Parent="Ayuda"
      />
      <BlogUsuariosRegistrarse />
      <Footer />
    </Layout>
  );
}
