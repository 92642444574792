import React from "react";
import SidebarCreators from "../SidebarCreators";

export default function BlogComoSuboFotosYvideos() {
    return (
        <>
            <div className="module ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-8">
                            <article className="post">
                                <div className="post-preview-phone">
                                </div>
                                <div className="post-wrapper">
                                    <div className="post-header">
                                        <h1 className="post-title">Subir fotos y videos al Feed</h1>
                                    </div>
                                    <div className="post-content">
                                        <p>
                                            Tu perfil puede tener fotos y videos ilimitadas, que los usuarios tendran acceso con su subscripcion
                                        </p>
                                        <p>
                                            Cuantas mas fotos y videos de calidad, que sean del agrado de los usuarios nuestro algoritmo posicionara mas tu perfil.
                                        </p>
                                        <p>
                                            Los precios mensuales de tus suscripciones pueden ser modificados cuando vos quieras. 
                                        </p>

                                        <div className="post-header">
                                            <h1 className="post-title">Pasos para subir Fotos y Videos</h1>
                                        </div>
                                        <ol>
                                            <li><b><strong> Con tu cuenta de creador, presiona el boton ( + ) que esta en el medio de tu menu inferior</strong></b></li>
                                            <li><b><strong> Elegi del desplegables la opcion fotos o videos, segun que quieras subir al feed. </strong></b></li>
                                            <li><b><strong> Veras la pantalla de Carga, inicia con la carga de la primera imagen que dara impacto al contenido, si es un video automaticamente se tomara como portada la mejor fotos de los primeros 3 segundos del video. </strong></b></li>
                                            <li><b><strong> Presiona el boton de cargar para finalizar la carga. </strong></b></li>
                                        </ol>
                                        {/* <img
                                            src="assets/img/blog/blog1RegistrarsePortada.jpg"
                                            alt="article"
                                            className="img-fluid"
                                        /> */}
                                    </div>
                                    <div className="post-footer">
                                        <div className="post-tags">
                                            <a href="/creadores">Creadores</a>
                                            <a href="/usuarios">Usuarios</a>
                                        </div>
                                    </div>
                                </div>
                            </article>
                            {/* <Comments /> */}
                        </div>
                        <SidebarCreators />
                    </div>
                </div>
            </div>
        </>
    );
}
