import React from "react";
import Footer from "../components/layout/footer/Footer";
import Navbar from "../components/layout/header/Navbar";
import Layout from "../components/layout/Layout";
import ExclusiveAgreementBenefits from "../components/exclusiveAgreement/ExclusiveAgreementBenefits";
import ExclusiveAgreementAsked from "../components/exclusiveAgreement/ExclusiveAgreementAsked";
import PageHeader from "../components/team/PageHeader";

export default function ExclusiveAgreementPage() {
    return (
        <Layout>
            <Navbar darkBg />
            {/* <PageHeader PageTitle="Acuerdos Exclusivos" /> */}
            <PageHeader HeaderTitle="Acuerdos Exclusivos" Parent="Info" PageTitle="Faq" />
            <ExclusiveAgreementBenefits />
            <ExclusiveAgreementAsked />
            <Footer />
        </Layout>
    );
}
