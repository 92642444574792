import React from "react";
import SidebarCreators from "../SidebarCreators";

export default function BlogExtraerDinero() {
    return (
        <>
            <div className="module ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-8">
                            <article className="post">
                                <div className="post-preview-phone">
                                </div>
                                <div className="post-wrapper">
                                    <div className="post-header">
                                        <h1 className="post-title">Extraer Dinero</h1>
                                    </div>
                                    <div className="post-content">
                                        <p>
                                            Para extraer dinero, tuviste que generar una venta o disponer de dinero acumulado.
                                        </p>
                                        <p>
                                            Recuerda que el dinero de las subscripciones que tus usuarios pagan se liberan luego de los 7 dias, el dinero pendiente lo podes visualizar en la solapa dinero a liberar. 
                                        </p>
                                        <p>
                                            Si tenes dolares podes convertir tus dolares, a moneda local (Unicamente vigente en Argentina). 
                                        </p>

                                        <div className="post-header">
                                            <h1 className="post-title">Pasos para Extraer tu Dinero</h1>
                                        </div>
                                        <ol>
                                            <li><b><strong> Presiona el boton de tu perfil , abajo a la derecha </strong></b></li>
                                            <li><b><strong> En el menu lateral presiona el boton Extracciones. </strong></b></li>
                                            <li><b><strong> Actualmente en la seccion de extracciones, presiona el boton Extraer Dinero. </strong></b></li>
                                            <li><b><strong> Elegi la cuenta de origen y la moneda, si no tienes una cuenta debes agregar una desde el boton Nueva cuenta o desde configuracione opcion Cuentas de Pago.</strong></b></li>
                                            <li><b><strong> Selecciona el monto a retirar, recuerda que hay un minimo de retiro. </strong></b></li>
                                            <li><b><strong> Luego de completar estas opciones, cuenta destino, moneda y monto, presiona extraer. </strong></b></li>
                                            <li><b><strong> Visualizaras el resumen de tu extraccion para confirmar la misma, con las comisiones que cobran las pasarelas de pago. </strong></b></li>
                                            <li><b><strong> Luego de confirmar tendras el dinero en tu cuenta, en menos de 24hs. </strong></b></li>
                                        </ol>
                                        {/* <img
                                            src="assets/img/blog/blog1RegistrarsePortada.jpg"
                                            alt="article"
                                            className="img-fluid"
                                        /> */}
                                    </div>
                                    <div className="post-footer">
                                        <div className="post-tags">
                                            <a href="/creadores">Creadores</a>
                                            <a href="/usuarios">Usuarios</a>
                                        </div>
                                    </div>
                                </div>
                            </article>
                            {/* <Comments /> */}
                        </div>
                        <SidebarCreators />
                    </div>
                </div>
            </div>
        </>
    );
}
