import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';


const SwipperGallery = () => {
  return (
    <Swiper
      // install Swiper modules
      modules={[Autoplay, Navigation, Pagination, Scrollbar, A11y]}
      centeredSlides={true}
      spaceBetween={50}
      slidesPerView={1}
      autoplay={{
        "delay": 2000,
        "disableOnInteraction": false}}
      // autoplay= {{delay: 2000}}
      loop = {true}
      // navigation
      pagination={{ clickable: true }}
      // scrollbar={{ draggable: true }}
      onSwiper={(swiper) => console.log(swiper)}
      // onSlideChange={() => console.log('slide change')}
    >
      <SwiperSlide>
        <img
          src="assets/img/Smartphone-2.png"
          alt="img"
          className="img-fluid"
        />
      </SwiperSlide>
      <SwiperSlide>
        <img
          src="assets/img/Smartphone-1.png"
          alt="img"
          className="img-fluid"
        />
      </SwiperSlide>
      <SwiperSlide>
        <img
          src="assets/img/Smartphone-3.png"
          alt="img"
          className="img-fluid"
        />
      </SwiperSlide>
      <SwiperSlide>
        <img
          src="assets/img/Smartphone-4.png"
          alt="img"
          className="img-fluid"
        />
      </SwiperSlide>
    </Swiper>
  );
};

export default SwipperGallery