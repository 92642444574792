
import React from "react";
import SidebarCreators from "../SidebarCreators";

export default function BlogCrearUnPack() {
    return (
        <>
            <div className="module ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-8">
                            <article className="post">
                                <div className="post-preview-phone">
                                </div>
                                <div className="post-wrapper">
                                    <div className="post-header">
                                        <h1 className="post-title">Crea tu Pack de fotos y Videos</h1>
                                    </div>
                                    <div className="post-content">
                                        <p>
                                            Un pack puede contener fotos, videos o una combinacion de ambos.
                                        </p>
                                        <p>
                                            Todos los packs tienen que tener una portada llamativa y no explicita.
                                        </p>
                                        <p>
                                            Los packs pueden categorizarse en diferentes tipos , tales como Bikini, Sado, Pies, etc.
                                        </p>

                                        <div className="post-header">
                                            <h1 className="post-title">Pasos para crear un Pack</h1>
                                        </div>
                                        <ol>
                                            <li><b><strong>Con tu cuenta de creador, presiona el boton ( + ) que esta en el medio de tu menu inferior</strong></b></li>
                                            <li><b><strong> Elegi la opcion de Pack en el Combo de Seleccio de Contenido </strong></b></li>
                                            <li><b><strong> Ingresa un titulo y una descripcion, estas seran visualizadas por el usuario para conocer mas</strong></b></li>
                                            <li><b><strong> Ingresa un precio tentador para incentivar la venta. Sugerimos por lo menos 7 Dolares por pack.</strong></b></li>
                                            <li><b><strong> Presiona el boton Siguiente. </strong></b></li>
                                            <li><b><strong> Veras la pantalla de Carga, inicia con la carga de una imagen para la portada, si es lo primero que cargas, quedara automaticamente asignada como portada. </strong></b></li>
                                            <li><b><strong> Continua con la carga del resto de las imagenes y videos que quieras que sean el contenido de tu pack. </strong></b></li>
                                        </ol>

                                        <div className="post-header">
                                            <h1 className="post-title">Sabias que podes compartir el pack creado</h1>
                                        </div>
                                        <ol>
                                            <li><b><strong>Ingresa a tu perfil y seleciona el boton de Packs Hotmy</strong></b></li>
                                            <li><b><strong> Presiona el boton de Ver sobre el pack que quieras. </strong></b></li>
                                            <li><b><strong> Arriba a la derecha sobre los ... superiores, tendras la opcion de compartir</strong></b></li>
                                            <li><b><strong> ya tienes el link ahora compartelo con tu comunidad.</strong></b></li>
                                        </ol>
                                        {/* <img
                                            src="assets/img/blog/blog1RegistrarsePortada.jpg"
                                            alt="article"
                                            className="img-fluid"
                                        /> */}
                                    </div>
                                    <div className="post-footer">
                                        <div className="post-tags">
                                            <a href="/creadores">Creadores</a>
                                            <a href="/usuarios">Usuarios</a>
                                        </div>
                                    </div>
                                </div>
                            </article>
                            {/* <Comments /> */}
                        </div>
                        <SidebarCreators />
                    </div>
                </div>
            </div>
        </>
    );
}
