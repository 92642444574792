import React from "react";
// import Pagination from "../components/blogs/Pagination";
import Footer from "../components/layout/footer/Footer";
import Navbar from "../components/layout/header/Navbar";
import Layout from "../components/layout/Layout";
import PageHeader from "../components/team/PageHeader";
import BlogCardAgencia from "../components/blogs/BlogCardAgencia"
import Pagination from "../components/blogs/Pagination";

export default function BlogAgencias() {
  return (
    <Layout>
      <Navbar darkBg />
      <PageHeader
        HeaderTitle="Ayuda para Agencias Productoras"
        Parent="Ayuda"
        PageTitle="Guias de Uso"
      />
      <BlogCardAgencia />
      <Pagination hasBg />
      <Footer />
    </Layout>
  );
}
