import React from "react";

export default function FeatureImg({ ImgSource }) {
  return (
    <>
      <div id="features" className="feature-section ptb-100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <div className="section-heading text-center mb-5">
                <span className="text-uppercase color-secondary sub-title">
                  Monetiza lo que te gusta hacer
                </span>
                <h2>Las Mejores Funciones para Tí</h2>
                <p>
                  Tenemos diferentes caminos para que puedas monetizar tu contenido, elegí el que mas te gusta y empeza a generar dinero.
                </p>
              </div>
            </div>
          </div>
          <div className="row row-grid align-items-center">
            <div className="col-lg-4">
              <div className="d-flex align-items-start mb-5">
                <div className="pr-4">
                  <div className="icon icon-shape icon-color-1 rounded-circle">
                    <span className="ti-share"></span>
                  </div>
                </div>
                <div className="icon-text">
                  <h5>Sistema de Referidos</h5>
                  <p className="mb-0">
                    Con cada creador que ingrese con tu link de referidos, obtendrás un 10% de cada una de las transacciones que tu referido genere.
                    Este 10% no afecta a la ganancia del referido, esta comisión es solventada por la plataforma. 
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-start mb-5">
                <div className="pr-4">
                  <div className="icon icon-shape icon-color-2 rounded-circle">
                    <span className="ti-gallery"></span>
                  </div>
                </div>
                <div className="icon-text">
                  <h5>Suscripciones</h5>
                  <p className="mb-0">
                    Sistema de suscripciones mensuales, trimestrales, semestrales y anuales con descuentos segun quiera el usuario otorgar.
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-start">
                <div className="pr-4">
                  <div className="icon icon-shape icon-color-3 rounded-circle">
                    <span className="ti-video-camera"></span>
                  </div>
                </div>
                <div className="icon-text">
                  <h5>Packs</h5>
                  <p className="mb-0">
                    Álbum de fotos y videos donde el usuario tiene que abonar un valor único para desbloquearlo, no esta asociado a la suscripción..
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="position-relative" style={{ zindex: 10 }}>
                <img
                  alt="placeholder"
                  src={ImgSource}
                  className="img-center img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="d-flex align-items-start mb-5">
                <div className="pr-4">
                  <div className="icon icon-shape icon-color-4 rounded-circle">
                    <span className="ti-layout-media-right"></span>
                  </div>
                </div>
                <div className="icon-text">
                  <h5>Acuerdos Comerciales</h5>
                  <p className="mb-0">
                    Los artistas van a poder pre-acordar con el fotógrafo/manager, una comisión que va a corresponder al servicio de multimedia aplicado en los Packs.
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-start mb-5">
                <div className="pr-4">
                  <div className="icon icon-shape icon-color-5 rounded-circle">
                    <span className="ti-money"></span>
                  </div>
                </div>
                <div className="icon-text">
                  <h5>Tips</h5>
                  <p className="mb-0">
                    Envío de propinas o apoyos a creadores de contenido. Gratificaciones para puedan mejorar sus producciones.
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-start">
                <div className="pr-4">
                  <div className="icon icon-shape icon-color-6 rounded-circle">
                    <span className="ti-headphone-alt"></span>
                  </div>
                </div>
                <div className="icon-text">
                  <h5>Soporte Exclusivo para creadores de contenido</h5>
                  <p className="mb-0">
                  En Hotmy alguien te escucha, podes comunicarte con nuestro soporte exclusivo cuando seas un creador.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
