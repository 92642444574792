import React from "react";
import { Link } from "react-router-dom";

export default function SidebarUsuarios() {
  return (
    <>
      <div className="col-lg-4 col-md-4">
        <div className="sidebar-left pr-4">
          <aside className="widget widget-categories">
            <div className="widget-title">
              <h6>Categorias</h6>
            </div>
            <ul>
              <li>
                <a href="/usuarios">
                  Usuarios <span className="float-right">2</span>
                </a>
              </li>
              <li>
                <a href="/creadores">
                  Creadores <span className="float-right">1</span>
                </a>
              </li>
              {/* <li>
                <a href="/agencias">
                  Agencias <span className="float-right">1</span>
                </a>
              </li> */}
            </ul>
          </aside>

          <aside className="widget widget-recent-entries-custom">
            <div className="widget-title">
              <h6>Te podria interesar</h6>
            </div>
            <ul>
              <li className="clearfix">
                <div className="wi">
                  <Link to="/comprar-un-pack">
                    <img
                      src="assets/img/blog/blog1RegistrarsePortada.jpg"
                      alt="recent post"
                      className="img-fluid rounded"
                    />
                  </Link>
                </div>
                <div className="wb">
                  <Link to="/comprar-un-pack">
                    Como comprar un Pack de fotos y videos.
                  </Link>
                  <span className="post-date">May 8, 2023</span>
                </div>
              </li>
              {/* <li className="clearfix">
                <div className="wi">
                  <Link to="/blog-details">
                    <img
                      src="assets/img/blog/2.jpg"
                      alt="recent post"
                      className="img-fluid rounded"
                    />
                  </Link>
                </div>
                <div className="wb">
                  <Link to="/blog-details">
                    Map where your photos were taken and discover local points.
                  </Link>
                  <span className="post-date">May 8, 2023</span>
                </div>
              </li> */}
              {/* <li className="clearfix">
                <div className="wi">
                  <a href="#/">
                    <img
                      src="assets/img/blog/3.jpg"
                      alt="recent post"
                      className="img-fluid rounded"
                    />
                  </a>
                </div>
                <div className="wb">
                  <Link to="/blog-details">
                    Map where your photos were taken and discover local points.
                  </Link>
                  <span className="post-date">May 8, 2023</span>
                </div>
              </li> */}
            </ul>
          </aside>
          <aside className="widget widget-tag-cloud">
            <div className="widget-title">
              <h6>Tags</h6>
            </div>
            <div className="tag-cloud">
              <a href="https://hotmy.app">Ir a la App</a>
              <a href="/comprar-un-pack">Como comprar un pack</a>
              {/* <a href="#//">Packs</a>
              <a href="#//">Fotos y Videos</a> */}
              <a href="/contactUs">Contacto</a>
            </div>
          </aside>
        </div>
      </div>
    </>
  );
}
