import React from "react";
import TerminosContent from "../components/blogs/TerminosContent";
import Footer from "../components/layout/footer/Footer";
import Navbar from "../components/layout/header/Navbar";
import Layout from "../components/layout/Layout";
import PageHeader from "../components/team/PageHeader";

export default function TerminosCondiciones() {
  return (
    <Layout>
      <Navbar darkBg />
      <PageHeader
        HeaderTitle="Terminos y Condiciones"
        Parent="Home"
        PageTitle="Terminos y Condiciones"
      />
      <TerminosContent/>
      <Footer />
    </Layout>
  );
}
