import React, { useState } from "react";
import ModalVideo from "react-modal-video";

export default function VideoPromoTwo() {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <section
        id="download"
        className="video-promo ptb-100 background-img"
        style={{
          background:
            "url('assets/img/video-bg.jpg')no-repeat center center / cover",
        }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div className="video-promo-content mt-4 text-center">
                <ModalVideo
                  channel="youtube"
                  isOpen={isOpen}
                  videoId="x4TfxdUoFWU"
                  onClose={() => setOpen(false)}
                />
                <button className="popup-youtube video-play-icon d-inline-block" onClick={() => setOpen(true)}>
                  <span
                    className="ti-control-play"
                  ></span>
                </button>
                <h5 className="mt-4 text-white">Mira nuestra Presentación</h5>

                <div className="download-btn mt-5">
                  <a href="https://hotmy.app/" className="btn google-play-btn m-3 ">
                    <span className="ti-android"></span> Google Play
                  </a>
                  <a href="https://hotmy.app/" className="btn app-store-btn m-3">
                    <span className="ti-apple"></span> App Store
                  </a>
                  <a href="https://hotmy.app/" className="btn app-store-btn m-3">
                    <span className="ti-html5"></span> Ir a la App
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
