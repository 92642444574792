import React from "react";

export default function Accordion({ Title }) {
  return (
    <>
      <section className={`container accordions ${Title ? "ptb-50" : ""}`}>
        {Title ? (
          <div className="row">
            <div className="col-lg-8 col-md-9">
              <div className="section-heading mb-5">
                <h2>Preguntas Frecuentes</h2>
                <p className="lead">
                  Esperamos solucionar todas tus dudas con éstas simples preguntas.
                </p>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="row">
          <div className="col-lg-6">
            <div id="accordion-1" className="accordion accordion-faq">
              <div className="card">
                <div
                  className="card-header py-4"
                  id="heading-1-1"
                  data-toggle="collapse"
                  role="button"
                  data-target="#collapse-1-1"
                  aria-expanded="false"
                  aria-controls="collapse-1-1"
                >
                  <h6 className="mb-0">
                    <span className="ti-receipt mr-3 color-secondary"></span>
                    Que es Hotmy?
                  </h6>
                </div>
                <div
                  id="collapse-1-1"
                  className="collapse"
                  aria-labelledby="heading-1-1"
                  data-parent="#accordion-1"
                >
                  <div className="card-body">
                    <p>
                      Una aplicación destinada para creadores que quieran darle valor a su contenido multimedia y generar ingresos con sus obras.
                      Estos ingresos pueden ser en moneda local o Internacional u$s.
                    </p>
                  </div>
                </div>
              </div>

              <div className="card">
                <div
                  className="card-header py-4"
                  id="heading-1-2"
                  data-toggle="collapse"
                  role="button"
                  data-target="#collapse-1-2"
                  aria-expanded="false"
                  aria-controls="collapse-1-2"
                >
                  <h6 className="mb-0">
                    <span className="ti-gallery mr-3 color-secondary"></span>
                    Quiénes pueden ser Creadores de Contenido?
                  </h6>
                </div>
                <div
                  id="collapse-1-2"
                  className="collapse"
                  aria-labelledby="heading-1-2"
                  data-parent="#accordion-1"
                >
                  <div className="card-body">
                    <p>
                      Únicamente personas mayores de edad según la ley de cada país, que validen correctamente su identidad.
                    </p>
                  </div>
                </div>
              </div>

              <div className="card">
                <div
                  className="card-header py-4"
                  id="heading-1-3"
                  data-toggle="collapse"
                  role="button"
                  data-target="#collapse-1-3"
                  aria-expanded="false"
                  aria-controls="collapse-1-3"
                >
                  <h6 className="mb-0">
                    <span className="ti-wallet mr-3 color-secondary"></span>
                    Quienes pueden registrarse?
                  </h6>
                </div>
                <div
                  id="collapse-1-3"
                  className="collapse"
                  aria-labelledby="heading-1-3"
                  data-parent="#accordion-1"
                >
                  <div className="card-body">
                    <p>
                      No hay limitaciones para registrarse, todos pueden ser parte de hotmy.
                    </p>
                  </div>
                </div>
              </div>

              
              <div className="card">
                <div
                  className="card-header py-4"
                  id="heading-1-4"
                  data-toggle="collapse"
                  role="button"
                  data-target="#collapse-1-4"
                  aria-expanded="false"
                  aria-controls="collapse-1-4"
                >
                  <h6 className="mb-0">
                    <span className="ti-wallet mr-3 color-secondary"></span>
                    Puedo usar Paypall en Hotmy para pagar?
                  </h6>
                </div>
                <div
                  id="collapse-1-4"
                  className="collapse"
                  aria-labelledby="heading-1-4"
                  data-parent="#accordion-1"
                >
                  <div className="card-body">
                    <p>
                      Si, podes usar Paypal para comprar contenido en nuestra plataforma.
                    </p>
                  </div>
                </div>
              </div>

              <div className="card">
                <div
                  className="card-header py-4"
                  id="heading-1-5"
                  data-toggle="collapse"
                  role="button"
                  data-target="#collapse-1-5"
                  aria-expanded="false"
                  aria-controls="collapse-1-5"
                >
                  <h6 className="mb-0">
                    <span className="ti-wallet mr-3 color-secondary"></span>
                    Tienen algun medio de contacto o soporte?
                  </h6>
                </div>
                <div
                  id="collapse-1-5"
                  className="collapse"
                  aria-labelledby="heading-1-5"
                  data-parent="#accordion-1"
                >
                  <div className="card-body">
                    <p>
                      Si, tenemos atencion exclusiva via whatsApp o Mail para nuestros usuarios donde se pueden comunicar en todo momento con nuestro equipo de expertos.
                    </p>
                  </div>
                </div>
              </div>


            </div>
          </div>
          <div className="col-lg-6">
            <div id="accordion-2" className="accordion accordion-faq">
              <div className="card">
                <div
                  className="card-header py-4"
                  id="heading-2-1"
                  data-toggle="collapse"
                  role="button"
                  data-target="#collapse-2-1"
                  aria-expanded="false"
                  aria-controls="collapse-2-1"
                >
                  <h6 className="mb-0">
                    <span className="ti-receipt mr-3 color-secondary"></span>
                    Hay que pagar para ser creador Contenido?
                  </h6>
                </div>
                <div
                  id="collapse-2-1"
                  className="collapse"
                  aria-labelledby="heading-2-1"
                  data-parent="#accordion-2"
                >
                  <div className="card-body">
                    <p>
                      Crearte el perfil es gratuito, no pagas creación de cuenta ni mantenimiento de la misma.
                    </p>
                  </div>
                </div>
              </div>

              <div className="card">
                <div
                  className="card-header py-4"
                  id="heading-2-2"
                  data-toggle="collapse"
                  role="button"
                  data-target="#collapse-2-2"
                  aria-expanded="false"
                  aria-controls="collapse-2-2"
                >
                  <h6 className="mb-0">
                    <span className="ti-lock mr-3 color-secondary"></span>
                    Cuando Puedo Retirar el Dinero?
                  </h6>
                </div>
                <div
                  id="collapse-2-2"
                  className="collapse"
                  aria-labelledby="heading-2-2"
                  data-parent="#accordion-2"
                >
                  <div className="card-body">
                    <p>
                      Cada pago ingresado se vera reflejado en Dinero a liquidar, luego de 7 dias y de haber alcanzado el monto minimo de extracción, 
                      el dinero estara disponible para ser retirado y transferido a tu cuenta.
                    </p>
                  </div>
                </div>
              </div>

              <div className="card">
                <div
                  className="card-header py-4"
                  id="heading-2-3"
                  data-toggle="collapse"
                  role="button"
                  data-target="#collapse-2-3"
                  aria-expanded="false"
                  aria-controls="collapse-2-3"
                >
                  <h6 className="mb-0">
                    <span className="ti-widget mr-3 color-secondary"></span> 
                    Puedo Extraer mis Dolares a Moneda Local?
                  </h6>
                </div>
                <div
                  id="collapse-2-3"
                  className="collapse"
                  aria-labelledby="heading-2-3"
                  data-parent="#accordion-2"
                >
                  <div className="card-body">
                    <p>
                    La aplicacion permite la conversion instantanea de Dolares a Moneda local (*).
                    No hagas mas procedimientos engorrosos, todo en una misma aplicación. (*) . 
                    Unicamente vigente para el Territorio de la Republica Argentina. Moneda Local hace referencia a Pesos Argentinos.
                    </p>
                  </div>
                </div>
              </div>

              <div className="card">
                <div
                  className="card-header py-4"
                  id="heading-2-4"
                  data-toggle="collapse"
                  role="button"
                  data-target="#collapse-2-4"
                  aria-expanded="false"
                  aria-controls="collapse-2-4"
                >
                  <h6 className="mb-0">
                    <span className="ti-widget mr-3 color-secondary"></span> 
                    Quien puede ver mi contenido?
                  </h6>
                </div>
                <div
                  id="collapse-2-4"
                  className="collapse"
                  aria-labelledby="heading-2-4"
                  data-parent="#accordion-2"
                >
                  <div className="card-body">
                    <p>
                    Unicamente los usuarios que abonen la suscripcion mensual a tu perfil o adquieran tus packs al valor que vos quieras.
                    </p>
                  </div>
                </div>
              </div>

              <div className="card">
                <div
                  className="card-header py-4"
                  id="heading-2-5"
                  data-toggle="collapse"
                  role="button"
                  data-target="#collapse-2-5"
                  aria-expanded="false"
                  aria-controls="collapse-2-5"
                >
                  <h6 className="mb-0">
                    <span className="ti-widget mr-3 color-secondary"></span> 
                    Que contenido se puede publicar?
                  </h6>
                </div>
                <div
                  id="collapse-2-5"
                  className="collapse"
                  aria-labelledby="heading-2-5"
                  data-parent="#accordion-2"
                >
                  <div className="card-body">
                    <p>
                    Eres libre de publicar cualquier tipo de contenido, si no sabes que tipo de contenido te conviene publicar segun tu perfil podes contactarte con nuestro soporte exclusivo.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
