import React from "react";
import BlogComoCompartirPerfil from "../../components/blogs/BlogDetails/BlogComoCompartirPerfil";
import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/team/PageHeader";

export default function HelpCreatorComoCompartirMiPerfil() {
  return (
    <Layout>
      <Navbar darkBg />
      <PageHeader
        HeaderTitle="Como compartir mi Perfil"
        PageTitle="Como compartir mi Perfil"
        Parent="Ayuda"
      />
      <BlogComoCompartirPerfil />
      <Footer />
    </Layout>
  );
}