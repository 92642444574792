import React from "react";
import BrandComponent from "../components/blogs/BrandComponent";
import Footer from "../components/layout/footer/Footer";
import Navbar from "../components/layout/header/Navbar";
import Layout from "../components/layout/Layout";
import PageHeader from "../components/team/PageHeader";

export default function Branding() {
  return (
    <Layout>
      <Navbar darkBg />
      <PageHeader
        HeaderTitle="Branding Hotmy"
        Parent="Home"
        PageTitle="Branding"
      />
      <BrandComponent light={true}/>
      <Footer />
    </Layout>
  );
}
