import React from "react";
import Contact from "../components/contact/Contact";
import Footer from "../components/layout/footer/Footer";
import Navbar from "../components/layout/header/Navbar";
import Layout from "../components/layout/Layout";
import PageHeader from "../components/team/PageHeader";


export default function ContactUs() {
  return (
    <Layout>
      <Navbar darkBg />
      <PageHeader PageTitle="Contactanos"   HeaderTitle="Contactanos"/>
      <Contact />
      <Footer/>
    </Layout>
  );
}
