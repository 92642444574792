import React from "react";
import BlogComoSuboFotosYvideos from "../../components/blogs/BlogDetails/BlogComoSuboFotosYvideos";
import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/team/PageHeader";

export default function HelpCreatorComoSuboFotosVideosFeed() {
  return (
    <Layout>
      <Navbar darkBg />
      <PageHeader
        HeaderTitle="Como subo Fotos y Videos"
        PageTitle="Como subo Fotos y Videos"
        Parent="Ayuda"
      />
      <BlogComoSuboFotosYvideos />
      <Footer />
    </Layout>
  );
}