



const ExpandexInfo = ( {Title, expandDetails, idExpandex}) => {
    return (
        <div className="single-feature mb-3 mt-3">
            <div className="lg-6">
                <div id={`accordion-${idExpandex}`} className="accordion accordion-faq">
                    <div className="card">
                        <div
                            className="card-header py-4"
                            id={`heading-${idExpandex}-1`}
                            data-toggle="collapse"
                            role="button"
                            data-target={`#collapse-${idExpandex}-1`}
                            aria-expanded="false"
                            aria-controls={`#collapse-${idExpandex}-1`}
                        >
                            <h6 className="mb-0">
                                <span className="ti-receipt mr-3 color-secondary"></span>
                                {Title}
                            </h6>
                        </div>
                        <div
                            id={`collapse-${idExpandex}-1`}
                            className="collapse"
                            aria-labelledby={`heading-${idExpandex}-1`}
                            data-parent={`#accordion-${idExpandex}`}
                        >
                            <div className="card-body">
                                <p>
                                    {expandDetails}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ExpandexInfo