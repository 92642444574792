import React from "react";
import ExpandexInfo from "./ExpandexInfo"

const ExclusiveAgreementBenefits = () => {
  return (
    <>
      <div className="overflow-hidden">
        <section id="about" className="about-us ptb-100 background-shape-img">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-7">
                <div className="about-content-left section-heading">
                  <h2>
                    Beneficios al Creador:
                  </h2>
                  <h5>
                  Son los beneficios que el creador seleccionado obtendrá por su acuerdo exclusivo.
                  </h5>
                  <ExpandexInfo
                    Title={"Dinero asegurado mensualmente, recibiendo un sueldo."}
                    expandDetails={"Todos los meses vas a poder retirar el dinero de tu acuerdo exclusivo, vas a recibir un sueldo por vender contenido."}
                    idExpandex={20}
                  />
                  <ExpandexInfo
                    Title={"Perfil destacado en la App con tilde de verificación."}
                    expandDetails={"La aplicación cuenta con cuentas verificadas que tienen mayor relevancia y seguridad, tu perfil es más confiable para los usuarios"}
                    idExpandex={21}
                  />
                  <ExpandexInfo
                    Title={"Promoción del perfil en todas las plataformas oficiales de Hotmy app."}
                    expandDetails={"Hotmy cuenta con varias plataformas oficiales y canales de difusión asociados"}
                    idExpandex={22}
                  />
                  <ExpandexInfo
                    Title={"Posicionamiento del perfil del 1 al 10 el TOP 100."}
                    expandDetails={"La aplicación tiene más de 100 perfiles destacados o de alta exposición, te aseguramos que en base a tu rendimiento vas a estar en el Top 100 entre la 1ra y 10ma ubicación"}
                    idExpandex={23}
                  />
                  <ExpandexInfo
                    Title={"Diseños y Reels Personalizados"}
                    expandDetails={"Nuestro departamento de diseño va a estar disponible para la creación de contenido en conjunto."}
                    idExpandex={24}
                  />
                  <ExpandexInfo
                    Title={"Producciones Oficiales hmy"}
                    expandDetails={"Nuestros fotografos oficiales organizarán sesiones para que tengas el mejor contenido exclusivo para la plataforma"}
                    idExpandex={25}
                  />
                  <ExpandexInfo
                    Title={"Indumentaria oficial hotmy app"}
                    expandDetails={"hmy, es nuestra linea premium, vas a recibir productos oficiales de la marca cuando asistas a las producciones fotográficas."}
                    idExpandex={26}
                  />
                  <ExpandexInfo
                    Title={"Bonos Hotmy por Rendimiento."}
                    expandDetails={"Te vamos a dar bonos por rendimientos y ventas, tu esfuerzo y tu red serán recompensadas"}
                    idExpandex={27}
                  />
                </div>
              </div>
              <div className="">
                <div className="about-content-right">
                  <img
                    src="assets/img/Smartphone-2.png"
                    alt="about us"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ExclusiveAgreementBenefits;
