import React from "react";
import Sidebar from "../SidebarUsuarios";

export default function BlogUsuariosComprarPack() {
  return (
    <>
      <div className="module ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-8">
              <article className="post">
                <div className="post-preview-phone">
                  {/* <img
                    src="assets/img/blog/blog1RegistrarsePortada.jpg"
                    alt="article"
                    className="img-fluid"
                  /> */}
                </div>
                <div className="post-wrapper">
                  <div className="post-header">
                    <h1 className="post-title">Como comprar un Pack?</h1>
                  </div>
                  <div className="post-content">
                    <p>
                      Para poder comprar un pack, tenes diferentes formas de acceso.
                    </p>
                    <ol>
                      <li><b>Buscando a tu creadora en su seccion de packs</b></li>
                      <li><b><strong>En nuestro buscador, en el modulo de packs, aqui encontraras el pack de todas los creadores</strong></b></li>
                      <li><b><strong>con el link del pack a comprar</strong></b></li>
                    </ol>
                    <p>
                      Todos nuestros metodos de accesos estan segurizados, mantenemos la integridad y discresion de cada usuario. Nunca te pediremos datos personales.
                    </p>
                    <div className="post-header">
                      <h1 className="post-title">Buscando a tu creadora en su seccion de packs</h1>
                    </div>

                    <img
                      src="assets/img/CapturaP7.jpg"
                      alt="article"
                      width={280}
                      className="img-fluid"
                    />


                    <div className="post-header">
                      <h1 className="post-title">Buscador de Packs</h1>
                    </div>


                    <img
                      src="assets/img/CapturaP3.jpg"
                      alt="article"
                      width={280}
                      className="img-fluid"
                    />

                    <div className="post-header">
                      <h3 className="post-title">Filtros disponibles en Packs</h3>
                    </div>

                    <p>Tenemos distintos tipos de filtros disponibles para vos, para poder ingresar a ellos en la seccion de buscador de packs, damos click en la esquina superior derecha.</p>
                    <p>En esta esquina vamos a encontrar un link de filtro que desplegara las siguientes opciones: </p>
                    <img
                      src="assets/img/CapturaP10.jpg"
                      alt="article"
                      width={280}
                      className="img-fluid"
                    />
                    <ol>
                      <li><b><strong>Filtro por Categorias (Bikinis, Comida, etc) , podras seleccionar los filtros que gustes</strong></b></li>
                      <li><b><strong>Ordenamiento por Mas Recientes</strong></b></li>
                      <li><b><strong>Ordenamiento por Mas Mas Vendidos</strong></b></li>
                      <li><b><strong>Ordenamiento por Mas Vendidos del Mes</strong></b></li>
                      <li><b><strong>Ordenamiento por Mas de la semana</strong></b></li>
                    </ol>

                    <p>
                      Para que los filtros se ejecuten, se debe presionar el boton <b><strong>Aplicar Filtros</strong></b>. 
                    </p>

                    <div className="post-header">
                      <h1 className="post-title">Via link de Pack</h1>
                    </div>
                    <p>
                      Puedes acceder via el enlace de tu creador.
                    </p>
                    <p>
                      Los enlaces suelen ser asi.
                    </p>
                    <a href="https://hotmy.app/packView/11501/539"> Enlace a Pack</a>
                  </div>
                  <div className="post-footer">
                    <div className="post-tags">
                      <a href="/usuarios">Usuarios</a>
                      <a href="/usuarios">Login</a>
                    </div>
                  </div>
                </div>
              </article>

              {/* <Comments /> */}
            </div>
            <Sidebar />
          </div>
        </div>
      </div>
    </>
  );
}
