import React from "react";
import AppRoutes from "./routes/Routes";
// import { FloatingWhatsApp } from 'react-floating-whatsapp'

const App = () => {
  return (
    <div>
      <AppRoutes />
      {/* <FloatingWhatsApp
        phoneNumber="123456789"
        accountName="Hotmy"
        avatar="assets/img/logo-white-1x.png"
        chatMessage="Gracias por comunicarte con nosotros en que podemos ayudarte"
        placeholder="Escribi tu mensaje"
        allowEsc
        allowClickAway
        notification
        notificationSound 
        buttonStyle= {{ backgroundColor: "#6624a9" ,   animation: "none"}}
        chatboxStyle= {{ backgroundColor: "#6624a9" }}
        /> */}
    </div>
  );
};

export default App;
