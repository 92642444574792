export const reviewData = [
  {
    id: 1,
    quote:
      "Rapidiously develop user friendly growth strategies after extensive initiatives. Conveniently grow innovative benefits through fully tested deliverables. Rapidiously utilize focused platforms through end-to-end schemas",
    authImg: "assets/img/client-1.jpg",
    authName: "Arabella Orin",
    authTitle: "AppCo",
  },
  {
    id: 2,
    quote:
      "Rapidiously develop user friendly growth strategies after extensive initiatives. Conveniently grow innovative benefits through fully tested deliverables. Rapidiously utilize focused platforms through end-to-end schemas",
    authImg: "assets/img/client-2.jpg",
    authName: "Arabella Orin",
    authTitle: "AppCo",
  },
  {
    id: 3,
    quote:
      "Rapidiously develop user friendly growth strategies after extensive initiatives. Conveniently grow innovative benefits through fully tested deliverables. Rapidiously utilize focused platforms through end-to-end schemas",
    authImg: "assets/img/client-3.jpg",
    authName: "Arabella Orin",
    authTitle: "AppCo",
  },
  {
    id: 4,
    quote:
      "Rapidiously develop user friendly growth strategies after extensive initiatives. Conveniently grow innovative benefits through fully tested deliverables. Rapidiously utilize focused platforms through end-to-end schemas",
    authImg: "assets/img/client-4.jpg",
    authName: "Arabella Orin",
    authTitle: "AppCo",
  },
  {
    id: 5,
    quote:
      "Rapidiously develop user friendly growth strategies after extensive initiatives. Conveniently grow innovative benefits through fully tested deliverables. Rapidiously utilize focused platforms through end-to-end schemas",
    authImg: "assets/img/client-5.jpg",
    authName: "Arabella Orin",
    authTitle: "AppCo",
  },
  {
    id: 6,
    quote:
      "Rapidiously develop user friendly growth strategies after extensive initiatives. Conveniently grow innovative benefits through fully tested deliverables. Rapidiously utilize focused platforms through end-to-end schemas",
    authImg: "assets/img/client-2.jpg",
    authName: "Arabella Orin",
    authTitle: "AppCo",
  },
];

export const blogData = [
  {
    id: 1,
    category: "Usuario",
    thumbnail: "assets/img/blog/ComoRegistrarme.jpg",
    date: "Ene 22, 2023",
    comments: "45",
    share: "10",
    title: "Como Registrarse",
    route: "registrarse",
    excerpt:
      "Registrate a la app en 3 simples pasos. Sincroniza tu cuenta con Google o Crea una cuenta Nueva",
  },
  {
    id: 2,
    category: "Packs",
    thumbnail: "assets/img/blog/ComoComprarPack.jpg",
    date: "Ene 21, 2023",
    comments: "45",
    share: "10",
    title: "Como Comprar un Pack",
    route: "comprar-un-pack",
    excerpt:
      "Encontra tu pack de Contenido favorito, podes elegir por creadores y distintos tipos de filtros que te permitiran encontrar el pack deseado.",
  },
  // {
  //   id: 3,
  //   category: "Suscripciones",
  //   thumbnail: "assets/img/blog/3.jpg",
  //   date: "Feb 26, 2023",
  //   comments: "41",
  //   share: "30",
  //   title: "Como suscribirse a un perfil",
  //   route: "suscribirse-a-un-creador",
  //   excerpt:
  //     "Como suscribirse a un perfil en 3 simples pasos. Busca el perfil de tu creador favorito, Presiona Suscribirte, realizar el pago con Paypal o Mercado Pago y en menos de un minuto tendras activo su contenido.",
  // },
  // {
  //   id: 4,
  //   category: "Contenido",
  //   thumbnail: "assets/img/blog/4.jpg",
  //   date: "April 26, 2019",
  //   comments: "41",
  //   share: "30",
  //   title: " Como ver el contenido que compre",
  //   route: "como-ver-el-contenido",
  //   excerpt:
  //     "Te suscribiste a una creadora o compraste un pack, conoce como ver el contenido que te gusta.",
  // },
  // {
  //   id: 5,
  //   category: "Regalos",
  //   thumbnail: "assets/img/blog/5.jpg",
  //   date: "April 26, 2019",
  //   comments: "41",
  //   share: "30",
  //   title: "Que son los Regalos?",
  //   route: "que-son-los-regalos",
  //   excerpt:
  //     "Sabias que podes enviar regalos a tu creador favorito, seguramente te agradecera si haces una contribucion.",
  // },
  // {
  //   id: 6,
  //   category: "Lista de deseos",
  //   thumbnail: "assets/img/blog/6.jpg",
  //   date: "April 26, 2019",
  //   comments: "41",
  //   share: "30",
  //   title: "Agregar a tu creadora a lista de deseos",
  //   route: "lista-de-deseos",
  //   excerpt:
  //     "Agrega usuarios y packs que te gusten a tu lista de deseados para comprarlos luego. No pierda de vista lo que te gusta.",
  // },
];

export const blogCreators = [
  {
    id: 1,
    category: "Creadores",
    thumbnail: "assets/img/blog/ComoSerCreador.jpg",
    date: "Jan 21, 2023",
    comments: "0",
    share: "122",
    title: "Quiero ser Creador de Contenido",
    route: "como-ser-creador-de-contenido",
    excerpt:
      "Queres ser creador de contenido te explicamos como.",
  },
  {
    id: 2,
    category: "Creadores",
    thumbnail: "assets/img/blog/ComoCompartirMiPerfil.jpg",
    date: "Feb 27, 2023",
    comments: "0",
    share: "82",
    title: "Compartir mi perfil",
    route: "como-compartir-mi-perfil",
    excerpt:
      "Compartir tu perfil es la mejor forma de aumentar tus ventas, aprende como hacerlo.",
  },
  {
    id: 3,
    category: "Creadores",
    thumbnail: "assets/img/blog/Como-Crea-un-Pack.jpg",
    date: "Mar 07, 2023",
    comments: "0",
    share: "33",
    title: "Crear un Pack de fotos y videos",
    route: "como-crear-un-pack",
    excerpt:
      "Crea un pack de fotos y videos, vende packs exclusivos sin una subscripcion, aumenta tus ingresos.",
  },
  {
    id: 4,
    category: "Creadores",
    thumbnail: "assets/img/blog/ComoserCreadordeContenido.jpg",
    date: "Mar 11, 2023",
    comments: "0",
    share: "201",
    title: "Subir contenido",
    route: "como-subir-fotos-videos",
    excerpt:
      "Crea contenido, subir fotos y videos a tu feed.",
  },
  {
    id: 5,
    category: "Creadores",
    thumbnail: "assets/img/blog/ComoSerCreadorGris.jpg",
    date: "Mar 21, 2023",
    comments: "0",
    share: "54",
    title: "Extraer Dinero",
    route: "como-extraer-dinero",
    excerpt:
      "Extrae el dinero generado por tus ventas, mira los resumenes de actividades y cuando se libera tu dinero",
  },
  {
    id: 5,
    category: "Creadores",
    thumbnail: "assets/img/blog/ComoSerCreadorGris.jpg",
    date: "Mar 21, 2023",
    comments: "0",
    share: "23",
    title: "Editar mi Perfil",
    route: "como-editar-mi-perfil",
    excerpt:
      "Edita tu foto de perfil, tus portadas, tus datos y precios de tus subscripciones.",
  }
];

export const blogAgencia = [
  {
    id: 1,
    category: "Agencia",
    thumbnail: "assets/img/blog/blog1RegistrarsePortada.jpg",
    date: "Jan 21, 2023",
    comments: "45",
    share: "10",
    title: "Como me convierto en creador de contenido",
    route: "como-ser-creador",
    excerpt:
      "Queres ser creador de contenido te explicamos como.",
  }
];
